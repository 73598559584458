import api from "./api-service";
import querystring from "querystring";

const url = "/sequences";

const find = (query) => {
  return api().get(`${url}?${querystring.stringify(query)}`);
};

const save = (obj) => {
  return api().post(url, obj);
};

const findOne = (id) => {
  return api().get(`${url}/${id}`);
};

const deletePointOrder = (pointOrder) => {
  return api().delete(`${url}/point-order/${pointOrder.id}`);
};

export { find, save, findOne, deletePointOrder };
