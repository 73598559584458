import api from "./api-service";

const url = "schools";

export const getAll = () => api().get(url);

export const create = (escola) => api().post(url, escola);

export const deleteApi = (escola) =>
  api().put(`${url}/${escola.id}`, { ...escola, visible: false });

export const update = (escola) => api().put(`${url}/${escola.id}`, escola);
