import api from "./api-service";
import { stringify } from "querystring";

const baseUrl = "therapy-templates";

export const findAll = (query) => api().get(`${baseUrl}?${stringify(query)}`);

export const getValuesByProperty = (property) =>
  api().get(baseUrl + "/property/" + property + "/values");

export const save = (template) => api().post(baseUrl, template);

export const update = (template) => api().put(baseUrl, template);

export const toggleActive = (templateId) =>
  api().patch(`${baseUrl}/${templateId}/active`);

export const getOne = (id) => api().get(baseUrl + "/" + id);
