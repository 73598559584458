<template>
  <v-container>
    <perseu-card title="Formulário de Modelo de Terapia">
      <template #content>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <v-text-field
                  :rules="[$rules.required]"
                  label="Nome"
                  v-model="template.name"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                  :rules="[$rules.required]"
                  label="Criado por"
                  v-model="template.createdBy"
              />
            </v-col>
            <v-col cols="4">
              <v-select
                  :items="escolas"
                  item-text="name"
                  item-value="id"
                  v-model="template.schoolId"
                  label="Selecione uma escola"
              />
            </v-col>
          </v-row>
          <v-textarea
              class="mt-5"
              outlined
              maxlength="500"
              counter
              label="Descrição"
              v-model="template.description"
              rows="3"
          />
        </v-form>
      </template>
    </perseu-card>
    <perseu-card class="mt-5" title="Itens do Modelo">
      <template #content>
        <v-form ref="formItem">
          <v-row align="center">
            <v-col cols="3">
              <v-select
                  :items="properties"
                  item-text="label"
                  item-value="value"
                  @change="loadValues"
                  v-model="formItem.selectedProperty"
                  label="Selecione uma propriedade"
                  :rules="[$rules.required]"
              />
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                  :items="formItem.values"
                  v-model="formItem.selectedValue"
                  label="Valor da propriedade"
                  item-text="name"
                  return-object
                  :rules="[$rules.required]"
              />
            </v-col>
            <v-col cols="3" v-if="isTypeSelected && formItem.selectedValue.id">
              <v-autocomplete
                  :items="formItem.sequences"
                  v-model="formItem.selectedSequence"
                  label="Sequência para o tipo selecionado"
                  item-text="name"
                  return-object
              />
            </v-col>
            <v-col cols="3">
              <v-btn @click="addItem" color="secondary">
                <v-icon class="mr-1">add_circle</v-icon>
                Adicionar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-list v-if="template.items.length > 0">
          <v-list-item
              v-for="item in template.items"
              :key="item.valueId"
              class="border-bottom-grey"
          >
            <v-list-item-content>
              <v-list-item-title>
                {{ item.property | propertyToFriendlyName }} -
                {{ item.valueText }}
              </v-list-item-title>

              <v-list-item-subtitle v-if="item.typeSequence?.name">
                Sequência: {{ item.typeSequence?.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <table-action-button
                  text="Remover item"
                  icon="delete"
                  color="red"
                  @click="removeItem(item)"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <empty-list class="mt-5" text="Nenhum item adicionado ainda" v-else/>
      </template>
    </perseu-card>
    <div class="d-flex justify-center mt-5">
      <v-btn large @click="back" color="secondary" class="mr-2" outlined>
        Voltar
      </v-btn>
      <v-btn large @click="submit" color="secondary"> Salvar e enviar</v-btn>
    </div>
  </v-container>
</template>

<script>
import {getAll} from "@/services/escolas-service";
import {propertiesWithNameArray, therapyTemplateProperties} from "@/misc/therapy-template-properties";
import {
  getOne,
  getValuesByProperty,
  save,
  update,
} from "@/services/therapy-templates-service";
import {pagesNames} from "@/router/pages-names";
import {find} from "@/services/sequences-service";

const formInitialState = () => ({
  name: "",
  description: "",
  createdBy: "",
  items: [],
  schoolId: undefined,
});

const formItemInitialState = () => ({
  selectedProperty: "",
  values: [],
  sequences: [],
  selectedValue: {
    id: 0,
    name: "",
  },
  selectedSequence: null
});

export default {
  name: "Form",
  data: () => ({
    template: formInitialState(),
    formItem: formItemInitialState(),
    properties: propertiesWithNameArray(),
    escolas: [],
  }),
  mounted() {
    if (this.$route.query?.id) {
      this.loadTemplate();
    }
  },
  created() {
    this.getEscolas();
  },
  computed: {
    isTypeSelected() {
      return this.formItem.selectedProperty === therapyTemplateProperties.TYPE;
    }
  },
  watch: {
    'formItem.selectedValue.id'() {
      this.loadSequencesWhenTypeIsSelected()
    }
  },
  methods: {
    async getEscolas() {
      try {
        const response = await getAll();

        this.escolas = response.data;
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    async loadValues() {
      try {
        this.$store.dispatch("loading/openDialog");
        const {data} = await getValuesByProperty(
            this.formItem.selectedProperty
        );
        this.formItem.values = data;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async loadSequencesWhenTypeIsSelected() {
      const {data} = await find({typeId: this.formItem.selectedValue.id});
      this.formItem.sequences = data;
    },
    addItem() {
      if(selectedProperty === therapyTemplateProperties.TYPE && [ 'Reservatório','Rastreio'].includes(selectedValue.name)) {
        this.$errorHandler({message: 'Selecione uma sequência para o tipo selecionado'});
        return;
      }
      if (!this.$refs.formItem.validate()) {
        return;
      }

      const {
        selectedProperty,
        selectedValue: {id, name},
        selectedSequence
      } = this.formItem;

      this.template.items.push({
        property: selectedProperty,
        valueText: name,
        valueId: id,
        typeSequence: selectedSequence
      });

      this.formItem = formItemInitialState();

      this.$refs.formItem.resetValidation();
    },
    removeItem({property, valueId}) {
      const index = this.template.items.findIndex(
          (item) => item.property === property && item.valueId === valueId
      );
      this.template.items.splice(index, 1);
    },
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.template?.id) {
        await this.update();
      } else {
        await this.create();
      }
    },
    async update() {
      try {
        this.$store.dispatch("loading/openDialog");
        await update(this.template);
        await this.loadTemplate();
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async create() {
      try {
        this.$store.dispatch("loading/openDialog");
        await save(this.template);
        this.$router.push({name: pagesNames.THERAPY_TEMPLATES_LIST});
        this.$toasted.global.defaultSuccess();
      } catch (error) {
        this.$errorHandler(error);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
    async loadTemplate() {
      try {
        const {data} = await getOne(this.$route.query.id);
        this.template = {
          id: data.id,
          name: data.name,
          createdBy: data.createdBy,
          description: data.description,
          items: data.items,
          schoolId: data.schoolId,
        };
      } catch (error) {
        this.$errorHandler(error);
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped></style>
